export default {
  apiUrl: ''
};
const siteConfig = {
  siteName: 'NFJ',
  siteNameMini: 'NFJ',
  siteIcon: 'ion-flash',
  linkIcon: './image/favicon.png',
  footerText: '©2021 Criado por NFJ Sistemas - V1.02.01',
  linkFooterText: 'https://www.nfj.com.br',
  addressFull: '',
  city: '',
  email: '',
  logo_171_41:'/assets/img/logo.png',
  logo:'/assets/img/logo.png',
  sobre_nos:'',
  linkedin:'',
  facebook:'',
  instagram: '',
  youtube:''
};
const firebaseConfig = {
  apiKey: "AIzaSyDPsAWEZM1VxNgJOBLcmRvHRr3VpVaguIU",
  authDomain: "nfjsistemas1.firebaseapp.com",
  projectId: "nfjsistemas1",
  storageBucket: "nfjsistemas1.appspot.com",
  messagingSenderId: "909950527471",
  appId: "1:909950527471:web:8dddfc1021828dcd15781a",
  measurementId: "G-QL719RG7PM"
};

export{
  siteConfig,
  firebaseConfig
}


