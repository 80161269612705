import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Page = lazy(() => import('./containers/BlankPage'));
// const Page = lazy(() => import('./containers/BabyGabiJu/GabiJu'));
const BabyGabiJu = lazy(() => import('./containers/BabyGabiJu/GabiJu'));
const Oasis = lazy(() => import('./containers/Oasis/Oasis'));

const publicRoutes = [
  
  {
    path: '/BabyGabiJu',
    exact: true,
    component: BabyGabiJu
  },
  {
    path: '/oasis',
    exact: true,
    component: Oasis
  },
  {
    path: '',
    exact: true,
    component: Oasis
  },
  // {
  //   path: PUBLIC_ROUTE.PAGE_404,
  //   component: lazy(() => import('@iso/containers/Pages/404/404')),
  // },
  // {
  //   path: PUBLIC_ROUTE.PAGE_500,
  //   component: lazy(() => import('@iso/containers/Pages/500/500')),
  // },
  // {
  //   path: PUBLIC_ROUTE.SIGN_IN,
  //   component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  // },
  // {
  //   path: PUBLIC_ROUTE.SIGN_UP,
  //   component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  // },
  // {
  //   path: PUBLIC_ROUTE.FORGET_PASSWORD,
  //   component: lazy(() =>
  //     import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
  //   ),
  // },
  // {
  //   path: PUBLIC_ROUTE.RESET_PASSWORD,
  //   component: lazy(() =>
  //     import('@iso/containers/Pages/ResetPassword/ResetPassword')
  //   ),
  // },
  // {
  //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
  //   component: lazy(() =>
  //     import('@iso/containers/Authentication/Auth0/Auth0Callback')
  //   ),
  // },
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  console.log(publicRoutes);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}


// import React, {lazy, Suspense}  from 'react';
// import { Route, Redirect, Router,Switch, Link } from 'react-router-dom';
// import { ConnectedRouter } from 'connected-react-router';
// import {useSelector, useDispatch} from 'react-redux';
// // import authAction from './redux/auth/actions';
// import Loader from './components/utility/loader';
// import Layout from "./components/Layout/";

//   const Page = lazy(() => import('./containers/BlankPage'));
//   const Dashboard = React.lazy(() => import("./containers/Dashboard/Dashboard"));

//   const RestrictedRoute= ({ component: Component, ...rest }) => {

//     const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);

//       return(
//       <Route
//         {...rest}
//         render={props =>
//           isLoggedIn ? (
//             <Component {...props} />
//           ) : (
//             <Redirect
//               to={{
//                 pathname:"/islogged",
//                 pg: window.location.pathname
//             }}
//             />
//           )
//         }
//       />)}

// /**
//  * 
//  * Rotas públicas, verifica depois do .com.br
//  */

  
// const PublicRoutes = ({ history }) => {
//   return (
//     <React.Fragment> 
//       <Suspense fallback={<Loader />}>
//         <ConnectedRouter history={history}>
//           <Router history={history}>
           
//             <Route
//               exact
//               path={'/'}
//               component={withLayout(Page)}
//             />

//           <RestrictedRoute
//             path="/dashboard"
//             component={Dashboard}
//           />  
//             <RestrictedRoute
//               path="/logged"
//               component={Dashboard}
//             /> 
//           </Router>
//         </ConnectedRouter>
//         </Suspense>
//     </React.Fragment>
//   );
// };

// function withLayout(WrappedComponent, hasDarkTopBar) {
//   // ...and returns another component...
//   return class extends React.Component {
//     render() {
//       return (
//         <Layout hasDarkTopBar={hasDarkTopBar}>
//           <WrappedComponent></WrappedComponent>
//         </Layout>
//       );
//     }
//   };
// }


// export default PublicRoutes;